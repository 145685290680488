"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _path = _interopRequireDefault(require("path"));
var _vuex = require("vuex");
var _validate = require("@/utils/validate");
var _Item = _interopRequireDefault(require("./Item"));
var _Link = _interopRequireDefault(require("./Link"));
var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {
      panzhiRoutes: ['/panzhiList/:type=1', '/panzhiList/:type=4', '/panzhiList/:type=3']
    };
  },
  computed: {
    // totalUnreadCount() {
    // 	return this.$store.state.settings.totalUnreadCount
    // },
    opened() {
      return this.$store.state.app.sidebar.opened;
    },
    ...(0, _vuex.mapGetters)(['quota', 'language'])
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {
          ...parent,
          path: '',
          noShowingChildren: true
        };
        return true;
      }
      return false;
    },
    addLink(url) {
      if ((url === '/searchMansion' || url === '/sale') && this.quota.sale_count === this.quota.sale_limit || url === '/deployEstate' && this.quota.rent_count === this.quota.rent_limit) {
        this.$message({
          message: '掲載中物件数が上限を超えたため、物件を追加することができません。物件一覧でご確認ください。',
          type: 'error'
        });
      }
    },
    resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }
      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }
      // if (routePath === '/deployEstate' && this.quota.rent_count + 1 === this.quota.rent_limit) {
      // 	this.$message({
      // 		message: '超出限额',
      // 		type: 'warning'
      // 	});
      // 	return false
      // }
      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  email: state => state.user.email,
  name: state => state.user.name,
  agentId: state => state.user.agent_id,
  useTranslate: state => state.user.is_use_translate,
  is_perfect: state => state.user.is_perfect,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  domains: state => state.user.domains,
  company: state => state.user.company,
  showAdv: state => state.user.showAdv,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  language: state => state.app.language,
  quota: state => state.user.quota,
  showClue: state => state.user.showClue,
  type: state => state.user.type,
  isCustimerClue: state => state.user.isCustimerClue,
  scope: state => state.user.scope,
  estate_form: state => state.estate.estate_form
};
var _default = exports.default = getters;
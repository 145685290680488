"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delFlyer = delFlyer;
exports.drawingDownload = drawingDownload;
exports.getFlyer = getFlyer;
exports.getFlyerList = getFlyerList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取房源盘纸
function getFlyer(type, param) {
  return (0, _request.default)({
    url: `/flyer/${type}/${param}`,
    method: 'get',
    baseUrl: '/v1'
  });
}
// 获取盘纸列表
function getFlyerList(param) {
  return (0, _request.default)({
    url: `/flyer`,
    method: 'get',
    baseUrl: '/v1',
    params: param
  });
}
// 删除盘纸
function delFlyer(param) {
  return (0, _request.default)({
    url: `/flyer/${param}`,
    method: 'delete',
    baseUrl: '/v1'
  });
}
// 导出pdf
function drawingDownload(param) {
  return (0, _request.default)({
    url: `/mansion/drawing/download?item_id=` + param,
    method: 'get',
    baseUrl: '/api/v1'
  });
}
// // 下载Excel模板
// export function downloadPDF(param) {
//   return request({
//     url: '/v1/mansion/drawing/download?item_id=' + param,
//     method: 'get',
//     baseUrl: '/api',
//     Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
//   })
// }
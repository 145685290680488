"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
require("core-js/modules/es.array.push.js");
var _router = require("@/router");
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return store.getters.scope === 2 && (route.path === '/clue' || route.path === '/chat') ? false : store.getters.scope === 2 && route.meta.roles.includes(222) || store.getters.scope === 1 && route.meta.roles.includes(111) && (JSON.stringify(route.meta.roles) === '[111]' || roles.some(role => route.meta.roles.includes(role)));
  }
  {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach(route => {
    const tmp = {
      ...route
    };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      //非定額套餐的公司展示"取得可能リスト" type: 1定額 2非定額
      if (!(tmp.name === "Clue" && store.state.user.type === 1)) {
        res.push(tmp);
      }
    }
  });
  return res;
}
const state = {
  routes: [],
  addRoutes: []
};
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  },
  SET_NO_ROUTES: (state, routes) => {
    state.addRoutes = [];
    state.routes = [];
  }
};
const actions = {
  generateRoutes({
    commit
  }, roles) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p_header" }, [
    _c("div", { staticClass: "left" }, [
      _c("img", {
        staticClass: "sidebar-logo",
        attrs: { src: require("../../assets/header-logo.png") },
      }),
      _c("h1", { staticClass: "sidebar-title" }, [
        _vm._v(_vm._s(_vm.title) + " "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "fixed-header" },
      [_c("Navbar", { attrs: { showHamburger: false } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _html2canvas = _interopRequireDefault(require("../../public/static/html2canvas"));
var _jspdf = _interopRequireDefault(require("jspdf"));
// 导出页面为PDF格式
// 将导出方法挂载到全局
var _default = exports.default = {
  install(Vue, options) {
    Vue.prototype.htmlToPdf = function (title, el) {
      // #pdfDom是要保存的页面元素
      return (0, _html2canvas.default)(document.querySelector(el), {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        async: true,
        scale: 2,
        dpi: 96,
        logging: true
      }).then(function (canvas) {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = Math.ceil(contentWidth / 595.28 * 841.89);
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // pdf页面偏移
        let position = 0;
        const w = (contentWidth + 10) / 2 * 0.75;
        const h = (contentHeight + 10) / 2 * 0.75;
        const i_h = contentHeight / 2 * 0.75;
        const pageData = canvas.toDataURL('image/jpeg/png', 1);
        const PDF = new _jspdf.default('l', 'pt', [w, h]);
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, w, i_h);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, w, i_h);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + '.pdf');
        return PDF.output('datauristring');
      });
    };
  }
};
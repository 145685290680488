"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
const {
  showSettings,
  tagsView,
  fixedHeader,
  sidebarLogo,
  isConnect
} = _settings.default;
const state = {
  theme: _elementVariables.default?.theme || '',
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  im: '',
  isConnect: ''
};
const mutations = {
  CHANGE_SETTING: (state, {
    key,
    value
  }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  // TOTALUNREADCOUNT: (state, count) => {
  // 	state.totalUnreadCount = count
  // },
  SETIMOBJECT: (state, im) => {
    state.im = im;
  },
  SETCONNECT: (state, value) => {
    state.isConnect = value;
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  },
  // setUnreadCount({ commit }, data) { // 设置chat未读数
  // 	commit('TOTALUNREADCOUNT', data)
  // },
  setInitIm({
    commit
  }, data) {
    commit('SETIMOBJECT', data);
  },
  setConnect({
    commit
  }, data) {
    commit('SETCONNECT', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInquiry = getInquiry;
exports.getUserInfo = getUserInfo;
exports.login = login;
exports.signin = signin;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: '/agent/user/login',
    method: 'post',
    data
  });
}
// 用户登录
function signin(data) {
  return (0, _request.default)({
    url: '/auth/signin',
    method: 'post',
    data,
    baseUrl: '/v1'
  });
}
function getUserInfo() {
  return (0, _request.default)({
    url: '/agent/info',
    method: 'get',
    baseUrl: '/v1'
  });
}

// export function logout() {
// 	return request({
// 		url: '/agent/user/logout',
// 		method: 'delete'
// 	})
// }

// 通过微信code换取用户信息
// export function getWXkey(code) {
// 	return request({
// 		url: '/auth/wechat-oauth',
// 		method: 'post',
// 		data: {
// 			code
// 		}
// 	})
// }

// 用户绑定微信
// export function bindWX(data) {
// 	return request({
// 		url: '/auth/bind',
// 		method: 'post',
// 		data
// 	})
// }

// 用户解除绑定微信
// export function unbindWX() {
// 	return request({
// 		url: '/auth/unbind',
// 		method: 'post'
// 	})
// }

// 申请微信sdk
// export function wXSDK(data) {
// 	return request({
// 		url: '/wechat/jssdk',
// 		method: 'post',
// 		data
// 	})
// }

function getInquiry(id) {
  return (0, _request.default)({
    url: `/agent/inquiry/${id}`,
    method: 'get',
    baseUrl: '/api/v2'
  });
}
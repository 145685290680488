"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(['quota'])
  },
  methods: {
    linkProps(url) {
      if ((0, _validate.isExternal)(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }
      if (url === '/deployEstate' && this.quota.rent_count === this.quota.rent_limit || (url === '/searchMansion' || url === '/sale') && this.quota.sale_count === this.quota.sale_limit) {
        return {
          is: 'router-link',
          to: ''
        };
      }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};
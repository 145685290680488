"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _index = _interopRequireDefault(require("./components/Sidebar/index.vue"));
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _index.default,
    TagsView: _components.TagsView
  },
  mixins: [_ResizeHandler.default],
  computed: {
    ...(0, _vuex.mapState)({
      sidebarValue: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebarValue.opened,
        openSidebar: this.sidebarValue.opened,
        withoutAnimation: this.sidebarValue.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billing = billing;
exports.getClientOptions = getClientOptions;
exports.getCustomerClue = getCustomerClue;
exports.grabbing = grabbing;
exports.sendFeedback = sendFeedback;
var _request = _interopRequireDefault(require("@/utils/request"));
// 线索列表
function getCustomerClue(query) {
  return (0, _request.default)({
    url: '/customer/clue',
    method: 'get',
    params: query,
    baseUrl: '/v1'
  });
}
// 发起抢单
function grabbing(id) {
  return (0, _request.default)({
    url: `/customer/grabbing/${id}/contend`,
    method: 'post',
    data: {},
    baseUrl: '/v1'
  });
}
// 反馈
function sendFeedback(id, data) {
  return (0, _request.default)({
    url: `/customer/profile/${id}/feedback`,
    method: 'post',
    data,
    baseUrl: '/v3'
  });
}
// 获取区域和预算下拉列表
function getClientOptions(query) {
  return (0, _request.default)({
    url: '/crm/client/options',
    method: 'get',
    params: query
  });
}
// 对账单列表
function billing(query) {
  return (0, _request.default)({
    url: '/customer/billing',
    method: 'get',
    params: query,
    baseUrl: '/v1'
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      {
                        attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) },
                        nativeOn: {
                          click: function ($event) {
                            return (function () {
                              return _vm.addLink(_vm.item.path)
                            })($event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: [
                              _vm.language === "en" ? "setSize" : "",
                              !_vm.isNest ? "submenu-title-noDropdown" : "",
                            ],
                            attrs: {
                              index: _vm.resolvePath(_vm.onlyOneChild.path),
                            },
                          },
                          [
                            _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon),
                                title:
                                  _vm.onlyOneChild.meta &&
                                  _vm.onlyOneChild.meta.title
                                    ? _vm.$t(
                                        "menu." + _vm.onlyOneChild.meta.title
                                      )
                                    : "",
                              },
                            }),
                            _vm.quota &&
                            _vm.panzhiRoutes.includes(_vm.item.path) &&
                            _vm.opened
                              ? _c("div", { staticClass: "quota" }, [
                                  _vm._v(
                                    " （" +
                                      _vm._s(
                                        _vm.quota.sale_flyer_count +
                                          "/" +
                                          _vm.quota.sale_flyer_limit
                                      ) +
                                      "件） "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.quota && _vm.item.path === "/panzhiList/"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "quota",
                                    attrs: { type: 2 && _vm.opened },
                                  },
                                  [
                                    _vm._v(
                                      " （" +
                                        _vm._s(
                                          _vm.quota.rent_flyer_count +
                                            "/" +
                                            _vm.quota.rent_flyer_limit
                                        ) +
                                        "件） "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  class: { setSize: _vm.language === "en" },
                  attrs: {
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item.meta
                        ? _c("item", {
                            attrs: {
                              icon: _vm.item.meta && _vm.item.meta.icon,
                              title:
                                _vm.item.meta && _vm.item.meta.title
                                  ? _vm.$t("menu." + _vm.item.meta.title)
                                  : "",
                            },
                          })
                        : _vm._e(),
                      _vm.quota && _vm.item.path === "/agent" && _vm.opened
                        ? _c("div", { staticClass: "quota" }, [
                            _vm._v(
                              " （" +
                                _vm._s(
                                  _vm.quota.sale_count +
                                    "/" +
                                    _vm.quota.sale_limit
                                ) +
                                "件） "
                            ),
                          ])
                        : _vm._e(),
                      _vm.quota && _vm.item.path === "/rental" && _vm.opened
                        ? _c("div", { staticClass: "quota" }, [
                            _vm._v(
                              " （" +
                                _vm._s(
                                  _vm.quota.rent_count +
                                    "/" +
                                    _vm.quota.rent_limit
                                ) +
                                "件） "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(_vm.item.children, function (child) {
                    return _c("sidebar-item", {
                      key: child.path,
                      staticClass: "nest-menu",
                      attrs: {
                        "is-nest": true,
                        item: child,
                        "base-path": _vm.resolvePath(child.path),
                      },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _user = require("@/api/user");
var _clue = require("@/api/clue");
var _account = require("@/api/account");
const state = {
  token: '',
  name: '',
  avatar: '',
  email: '',
  agent_id: '',
  is_perfect: 0,
  is_use_translate: 0,
  domains: '',
  roles: [],
  quota: '',
  showClue: false,
  isCustimerClue: false,
  company: '',
  showAdv: true,
  scope: '',
  type: ''
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_ADV: (state, showAdv) => {
    state.showAdv = showAdv;
  },
  SET_DOMAIN: (state, domains) => {
    state.domains = domains;
  },
  SET_AGENTID: (state, agent_id) => {
    state.agent_id = agent_id;
  },
  SET_IS_PERFECT: (state, is_perfect) => {
    state.is_perfect = is_perfect;
  },
  SET_TRANSLATE: (state, is_use_translate) => {
    state.is_use_translate = is_use_translate;
  },
  SET_QUOtA: (state, quota) => {
    state.quota = quota;
  },
  SET_SCOPE: (state, scope) => {
    state.scope = scope;
  },
  SET_COMPANY: (state, company) => {
    state.company = company;
  },
  SET_CLUE: (state, showClue) => {
    state.showClue = showClue ? true : false;
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  SET_CUSTOMERCLUE: (state, isCustimerClue) => {
    state.isCustimerClue = isCustimerClue ? true : false;
  }
};
const actions = {
  // 显示/隐藏
  showAdVisible({
    commit
  }, visible) {
    commit('SET_ADV', visible);
  },
  // user login 暂时没用
  login({
    commit
  }, userInfo) {
    const {
      username,
      password
    } = userInfo;
    return new Promise((resolve, reject) => {
      (0, _user.signin)({
        username: username.trim(),
        password: password
      }).then(response => {
        const {
          domains
        } = response;
        const {
          result
        } = response;
        const {
          roles,
          name,
          avatar,
          id,
          email,
          is_perfect,
          company,
          scope,
          is_use_translate,
          is_parent,
          type
        } = result;
        let newRoles = [...roles];
        // roles must be a non-empty array
        if (!newRoles || newRoles.length <= 0) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        const account = is_parent ? 'ADMIN' : 'ADMIN_SUB';
        if (scope === 2) {
          newRoles = [];
        }
        newRoles.push(account);
        // newRoles.push(scopeVal);
        const obj = Object.assign(result, domains);
        commit('SET_ROLES', newRoles);
        commit('SET_SCOPE', scope);
        commit('SET_DOMAIN', domains);
        commit('SET_NAME', name);
        commit('SET_EMAIL', email);
        commit('SET_AVATAR', avatar);
        commit('SET_AGENTID', id);
        commit('SET_COMPANY', company);
        commit('SET_IS_PERFECT', is_perfect);
        commit('SET_TRANSLATE', is_use_translate);
        commit('SET_TOKEN', result.access_token);
        commit('SET_TYPE', type);
        // setToken(result.access_token)
        // setUserInfo(JSON.stringify(obj))
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 抢单
  getCustomerClue({
    commit
  }) {
    return new Promise((resolve, reject) => {
      const obj = {
        page: 1,
        limit: 1000,
        status: 1
      };
      (0, _clue.getCustomerClue)(obj).then(res => {
        if (res.code === 1000200) {
          const data = res.result.data;
          if (data.length) {
            resolve(true);
            commit('SET_CUSTOMERCLUE', 1);
          } else {
            commit('SET_CUSTOMERCLUE', 0);
          }
        }
      });
    });
  },
  // 配额
  accountQuota({
    commit
  }) {
    return new Promise((resolve, reject) => {
      (0, _account.getAccountQuota)().then(res => {
        if (res.code === 1000200) {
          const {
            result
          } = res;
          commit('SET_QUOtA', result);
          resolve();
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  // get user info
  getUserInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      // 获取用户信息 如果返回401 则去获取微信信息
      (0, _user.getUserInfo)().then(response => {
        const {
          domains
        } = response;
        const {
          result
        } = response;
        if (!result) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        const {
          roles,
          name,
          avatar,
          id,
          email,
          is_perfect,
          is_use_translate,
          is_parent
        } = result;
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        const account = is_parent ? 'ADMIN' : 'ADMIN_SUB';
        roles.push(account);
        commit('SET_ROLES', roles);
        commit('SET_DOMAIN', domains);
        commit('SET_NAME', name);
        commit('SET_EMAIL', email);
        commit('SET_AVATAR', avatar);
        commit('SET_AGENTID', id);
        commit('SET_IS_PERFECT', is_perfect);
        commit('SET_TRANSLATE', is_use_translate);
        resolve(result);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 重置用户信息
  resetInfo({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_ROLES', []);
      commit('SET_NAME', '');
      commit('SET_AVATAR', '');
      commit('SET_AGENTID', '');
      resolve();
    });
  },
  // 退出
  logout({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', false);
      commit('SET_ROLES', []);
      this.commit('permission/SET_NO_ROUTES', []);
      this.commit('globalData/OVERSEAS_DATA', {});
      resolve();
      location.reload();
      //   logout().then((res) => {
      //     if (res.code === 1000200) {

      //       // removeToken()
      //       // reset visited views and cached views
      //       // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      //       // dispatch('tagsView/delAllViews', null, { root: true })
      //       resolve()
      //     }
      //   }).catch(error => {
      //     reject(error)
      //   })
    });
  },
  // remove token 暂时没用
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      // removeToken()
      resolve();
    });
  }

  // dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async resolve => {
  //     //const token = role + '-token'
  //     //commit('SET_TOKEN', token)
  //     //setToken(token)
  //     const { roles } = await dispatch('getInfo')
  //     resetRouter()
  //     // generate accessible routes map based on roles
  //     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)
  //     // reset visited views and cached views
  //     //dispatch('tagsView/delAllViews', null, { root: true })
  //     resolve()
  //   })
  // }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _panzhi = require("@/api/panzhi");
const state = {
  type: window.sessionStorage.getItem('type'),
  estateId: window.sessionStorage.getItem('estateId')
};
const mutations = {
  ESTATETYPE: (state, type) => {
    state.type = type;
    window.sessionStorage.setItem('type', type);
  },
  ITEMID: (state, estateId) => {
    state.estateId = estateId;
    window.sessionStorage.setItem('estateId', estateId);
  }
};
const actions = {
  getFlyer({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      (0, _panzhi.getFlyer)(data.type, data.id).then(res => {
        if (res.code === 1000200) {
          commit('ESTATETYPE', res.result.type);
          commit('ITEMID', res.result.item_id);
          resolve(res.result);
        } else {
          reject(res);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.push.js");
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _validate = require("@/utils/validate");
// import Vue from 'vue'

// progress bar
// progress bar style

let cRoles = [];
let accessRoutes;
let flag = true;
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configurationmail/rental_inquiry/{id}
const whiteList = ['/401', '/404']; // no redirect whitelist

_router.default.beforeEach(async (to, from, next) => {
  if (to.path === '/login') cRoles = [];
  if (to.meta.noCache === false) {
    _store.default.dispatch('tagsView/addCachedView', to);
  }
  if (to.path !== '/' && to.path !== '/login') {
    if (flag && !to.meta.isWhite && whiteList.indexOf(to.path) === -1 && !(0, _validate.isInquiry)(to.path) && to.path !== '/dashboard' && _store.default.getters.scope === 1) {
      flag = false;
      _store.default.dispatch('user/accountQuota');
    }
    // start progress bar
    _nprogress.default.start();
    // set page title
    // document.title = getPageTitle(to.meta.title, to.meta.independent)
    // determine whether the user has logged in
    if (whiteList.indexOf(to.path) !== -1 || (0, _validate.isInquiry)(to.path) || to.meta.isWhite) {
      // 是白名单的route
      next();
    } else {
      if (!_store.default.getters.token && !whiteList.indexOf(to.path) !== -1 && !(0, _validate.isInquiry)(to.path)) {
        _router.default.push('/login');
        return;
      }
      cRoles = _store.default.getters.roles;
      if (!cRoles.length > 0) {
        var {
          roles
        } = await _store.default.dispatch('user/getUserInfo');
        cRoles = roles;
      }
      if (cRoles && cRoles.length > 0) {
        const hasRoles = _store.default.getters.roles && _store.default.getters.roles.length > 0;
        if (hasRoles && _store.default.getters.permission_routes && _store.default.getters.permission_routes.length > 0) {
          next();
        } else {
          try {
            accessRoutes = await _store.default.dispatch('permission/generateRoutes', cRoles);
            _router.default.selfaddRoutes(_router.default, accessRoutes);
            next({
              ...to,
              replace: true
            });
          } catch (error) {
            await _store.default.dispatch('user/resetInfo'); // 报错清空登录信息
            _router.default.push({
              path: '/login'
            });
            _nprogress.default.done();
          }
        }
      } else {
        if (to.path.indexOf('/clue') !== -1) _router.default.push({
          path: `/login?redirect=${to.path}`
        });else if (to.path !== '/login') _router.default.push({
          path: '/login'
        });
        _nprogress.default.done();
      }
    }
  } else {
    next();
  }
});
_router.default.afterEach((to, from) => {
  if (from.meta.noCache === false) {
    const {
      meta: {
        keepComponentPages,
        alwaysKeep
      }
    } = from; // alwaysKeep 一直被缓存
    if (!alwaysKeep && (!keepComponentPages || !keepComponentPages.includes(to.name))) {
      _store.default.dispatch('tagsView/delCachedView', from);
    }
  }
  _nprogress.default.done();
});
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
const state = {
  logs: []
};
const mutations = {
  ADD_ERROR_LOG: (state, log) => {
    state.logs.push(log);
  },
  CLEAR_ERROR_LOG: state => {
    state.logs.splice(0);
  }
};
const actions = {
  addErrorLog({
    commit
  }, log) {
    commit('ADD_ERROR_LOG', log);
  },
  clearErrorLog({
    commit
  }) {
    commit('CLEAR_ERROR_LOG');
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _index = require("@/utils/index");
var _index2 = require("@/lang/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Navbar",
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  props: {
    showHamburger: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShow: !(0, _index.isFromWeiXin)(),
      defaultPic: require("../../assets/customer.png"),
      routeName: '',
      rightMenuWidth: 0
      // langs: {
      //   'ja': '日本語',
      //   'en': 'English'}
    };
  },
  // watch: {
  //   '$route': {
  //     deep: true,
  //     handler(route) {
  //       debugger
  //       const globalPath = ['/overseasList', '/overseasEdit'];
  //       this.langs = globalPath.includes(route.path) ? {'zh-CN': '中文', 'en': 'English' } : { 'ja': '日本語' };
  //     },
  //     immediate: true
  //   }
  // },
  watch: {
    '$route': {
      deep: true,
      handler(route) {
        this.routeName = route.name;
      },
      immediate: true
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)(["sidebar", "avatar", "device", "name", "domains", "language", "showClue", "isCustimerClue", "roles", "scope", "type"]),
    rightMenuStyle() {
      return {
        '--rightMenuWidth': this.rightMenuWidth + 'px'
      };
    }
    // displayedLang() {
    //   return this.langs[this.language];
    // },
  },
  created() {
    // this.langs = this.scope === 1 ? { 'ja': '日本語',} : {'en': 'English'}
    if (this.showClue && this.scope === 1) {
      this.$store.dispatch("user/getCustomerClue");
    }
  },
  mounted() {
    this.rightMenuWidth = document.querySelector('.right-menu').offsetWidth;
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    // 切换语言
    // switchLang(targetLang) {
    //   if (targetLang !== this.language) {
    //     this.$store.dispatch("app/setLanguage", targetLang);
    //     loadLanguageAsync(targetLang).then(res => {
    //       this.$router.push(this.$route.path);
    //     })
    //   }
    // },
    closeChange(f) {
      this.$store.commit("user/SET_CUSTOMERCLUE", 0);
      this.$store.commit("user/SET_CLUE", 0);
      this.show = false;
      if (f) this.$router.push({
        path: "/clue",
        query: {
          from: true
        }
      });
    },
    // 退出登录
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },
    // 完善中介信息
    perfectInfo() {
      this.$router.push("/perfectInfo");
    },
    // 更新密码
    updatePassword() {
      this.$router.push("/updatedPassword");
    },
    close() {
      this.visible = false;
    }
  }
};
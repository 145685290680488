import { render, staticRenderFns } from "./PzHeader.vue?vue&type=template&id=57235a38"
import script from "./PzHeader.vue?vue&type=script&lang=js"
export * from "./PzHeader.vue?vue&type=script&lang=js"
import style0 from "./PzHeader.vue?vue&type=style&index=0&id=57235a38&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/frontend/web/agent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57235a38')) {
      api.createRecord('57235a38', component.options)
    } else {
      api.reload('57235a38', component.options)
    }
    module.hot.accept("./PzHeader.vue?vue&type=template&id=57235a38", function () {
      api.rerender('57235a38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/PzHeader.vue"
export default component.exports
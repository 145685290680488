var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar", style: _vm.rightMenuStyle },
    [
      _vm.isShow && _vm.showHamburger
        ? _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          })
        : _vm._e(),
      _vm.isShow && _vm.showHamburger
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src:
                      _vm.avatar &&
                      (_vm.avatar.indexOf("jpg") !== -1 ||
                        _vm.avatar.indexOf("png") !== -1 ||
                        _vm.avatar.indexOf("jpeg") !== -1)
                        ? _vm.domains.image +
                          "/" +
                          _vm.avatar +
                          "?imageView2/1/w/80/h/80"
                        : _vm.defaultPic,
                  },
                }),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v("Hi! " + _vm._s(_vm.name) + "様"),
                ]),
                _vm.isShow
                  ? _c("i", { staticClass: "el-icon-caret-bottom" })
                  : _vm._e(),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.isShow
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.perfectInfo($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.23"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isShow
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.updatePassword($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.30"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isShow
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(_vm._s(_vm.$t("login.18"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.routeName === "Dashboard",
              expression: "routeName==='Dashboard'",
            },
          ],
          staticClass: "center-menu",
        },
        [_vm._m(0)]
      ),
      _vm.showClue &&
      _vm.isCustimerClue &&
      _vm.roles.includes("GRABBING") &&
      _vm.roles.includes(16) &&
      _vm.scope === 1
        ? _c(
            "el-alert",
            {
              staticClass: "alert",
              attrs: {
                title: "",
                type: "info",
                effect: "dark",
                "show-icon": "",
              },
              on: { close: _vm.closeChange },
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", [
                  _vm._v(" 新しい反響情報を取得しました "),
                  _c(
                    "span",
                    {
                      staticClass: "clur",
                      on: {
                        click: function () {
                          return _vm.closeChange(true)
                        },
                      },
                    },
                    [_vm._v("今すぐ取得する")]
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "con_Warning" }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _c("span", { staticClass: "warn_title" }, [
        _vm._v("【悪質な換金詐欺に関する注意喚起】"),
      ]),
      _c("span", [
        _vm._v(
          "最近、物件購入者を装って、不動産会社様に購入資金の換金を依頼し、 信じ込ませるために偽の振込証明書などを送付して、 日本円を特定の口座に振り込ませる詐欺の手口が確認されています。 お客様におかれまして、十分にご注意くださいますようお願い申し上げます。 もし万が一被害に遭われた場合は、速やかに最寄りの警察署へご相談ください。 ※振り込め詐欺業者とお客様間でのトラブルに関しまして当社は一切の責任を負いかねます"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("@/store"));
var _validate = require("@/utils/validate");
var _settings = _interopRequireDefault(require("@/settings"));
// you can set in settings.js
// errorLog:'production' | ['production', 'development']
const {
  errorLog: needErrorLog
} = _settings.default;
function checkNeed() {
  const env = process.env.NODE_ENV;
  if ((0, _validate.isString)(needErrorLog)) {
    return env === needErrorLog;
  }
  if ((0, _validate.isArray)(needErrorLog)) {
    return needErrorLog.includes(env);
  }
  return false;
}
if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info, a) {
    // Don't ask me why I use Vue.nextTick, it just a hack.
    // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
    _vue.default.nextTick(() => {
      _store.default.dispatch('errorLog/addErrorLog', {
        err,
        vm,
        info,
        url: window.location.href
      });
      console.error(err, info);
    });
  };
}
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
const state = {
  estate_form: {}
};
const mutations = {
  ESTATE_DATA: (state, form_data) => {
    state.estate_form = form_data;
  }
};
const actions = {
  setEstateDatas({
    commit
  }, data) {
    commit('ESTATE_DATA', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};
"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _store = _interopRequireDefault(require("@/store"));
const $ = require('../../../../public/static/jquery.min');
module.exports = {
  // init
  initData: function (context) {
    var id = context.state.estateId;
    $.ajax({
      url: context.state.getData + '/' + id,
      method: 'GET',
      dataType: 'json',
      headers: {
        Authorization: 'Bearer  ' + _store.default.getters.token
      },
      success: function (data) {
        // TODO: not save! to call the outer var
        context.commit('initialRawData', data.result.rawData);
        context.commit('initialCurrency', data.result.currency);
        context.commit('initialOwnership', data.result.isOwner);
        if (data.result.imageSetting) {
          context.commit('initImageSetting', data.result.imageSetting);
        }
        if (data.result.currencyType) {
          context.commit('updateCurrency', data.result.currencyType);
        }
        if (data.result.langType) {
          context.commit('updateLanguage', data.result.langType);
        }
        if (data.result.panzhiType) {
          context.commit('updatePanzhiType', data.result.panzhiType);
        }

        // layout
        var layoutType = data.result.layoutType ? data.result.layoutType : 'DEFAULT_LAYOUT_1';
        context.commit('updatePanzhiLayoutType', layoutType);
        if (data.result.partsLayout) {
          context.commit('updatePartsLayout', data.result.partsLayout);
        }
        var appearanceType = data.result.appearanceType ? data.result.appearanceType : 'DEFAULT_APPEARANCE_1';
        context.commit('updatePanzhiAppearanceType', appearanceType);
        if (data.result.partsAppearance) {
          context.commit('updateAppearance', data.result.partsAppearance);
        }
        // loaded
        context.commit('loaded');
        context.commit('msgBoxClose');
      },
      error: function (error) {
        // TODO:
        // if can not get data, show error message and force to disable the page
        context.commit('msgBoxOpen', 'LOADING_ERROR');
      }
    });
  },
  // save
  saveData: function (context, isLoad = true) {
    if (isLoad) context.commit('msgBoxOpen', 'SAVE');
    var id = context.state.estateId;
    var api_url = context.state.saveData;
    var data = {
      isOwner: context.state.isOwner,
      panzhiType: context.state.panzhiType,
      langType: context.state.langType,
      currencyType: context.state.currencyType,
      rawData: context.state.rawData,
      imageSetting: context.state.imageSetting,
      layoutType: context.state.layoutType,
      partsLayout: context.state.partsLayout,
      appearanceType: context.state.appearanceType,
      partsAppearance: context.state.partsAppearance
    };
    data = JSON.stringify(data);
    $.ajax({
      url: api_url,
      method: 'POST',
      data: JSON.stringify({
        item_id: id,
        data: data
      }),
      dataType: 'json',
      headers: {
        Authorization: 'Bearer ' + _store.default.getters.token,
        'Content-Type': 'application/json'
      },
      cache: false,
      success: function (result) {
        if (result.code == 1000200) {
          context.commit('msgBoxClose');
        } else if (result.code == 1000422) {
          context.commit('msgBoxOpen', 'LIMITED_ERROR');
        } else {
          context.commit('msgBoxOpen', 'SAVE_ERROR');
        }
      },
      error: function (error) {
        // TODO:
        // if can not get data, show error message and force to disable the page
        context.commit('msgBoxOpen', 'SAVE_ERROR');
      }
    });
  },
  // save layout
  saveLayout: function (context, name) {
    $.ajax({
      url: context.state.saveLayout,
      method: 'POST',
      data: JSON.stringify({
        type: context.state.layoutApiType,
        name: name,
        setting: JSON.stringify(context.state.partsLayout)
      }),
      dataType: 'json',
      headers: {
        Authorization: 'Bearer ' + _store.default.getters.token,
        'Content-Type': 'application/json'
      },
      cache: false,
      success: function (result) {
        console.log(result);
        if (result.code == 200 && result.detail.id) {
          context.commit('addLayoutSetting', {
            id: result.detail.id,
            name: name,
            setting: context.state.partsLayout
          });
          $('.saveBox').hide();
          context.commit('msgBoxOpen', 'SAVE_LAYOUT_DONE');
        } else {
          $('.saveBox').hide();
          context.commit('msgBoxOpen', 'SAVE_LAYOUT_ERROR');
        }
      },
      error: function (error) {
        // TODO:
        // if can not get data, show error message and force to disable the page
        $('.saveBox').hide();
        context.commit('msgBoxOpen', 'SAVE_LAYOUT_ERROR');
      }
    });
  },
  // save appearance
  saveAppearance: function (context, name) {
    $.ajax({
      url: context.state.saveAppearance,
      method: 'POST',
      data: JSON.stringify({
        type: context.state.layoutApiType,
        name: name,
        setting: JSON.stringify(context.state.partsAppearance)
      }),
      dataType: 'json',
      cache: false,
      headers: {
        Authorization: 'Bearer ' + _store.default.getters.token,
        'Content-Type': 'application/json'
      },
      success: function (result) {
        if (result.code == 200 && result.detail.id) {
          context.commit('addAppearanceSetting', {
            id: result.detail.id,
            name: name,
            setting: context.state.partsAppearance
          });
          $('.saveBox').hide();
          context.commit('msgBoxOpen', 'SAVE_COLOR_DONE');
        } else {
          $('.saveBox').hide();
          context.commit('msgBoxOpen', 'SAVE_COLOR_ERROR');
        }
      },
      error: function (error) {
        // TODO:
        // if can not get data, show error message and force to disable the page
        console.log(error);
        $('.saveBox').hide();
        context.commit('msgBoxOpen', 'SAVE_COLOR_ERROR');
      }
    });
  }
};